import React from "react";
import Footer from "../../components/NewComponents/Footer";
import TopProduct from "../../components/NewComponents/Product";
import Header from "../../components/NewComponents/Header";
import SideButton from "../../components/NewComponents/SideButton";
// import useDebugger from '../../hooks/useDebugger';

// page外的资源可以用用@符号引入
export default function Product() {
  // useDebugger();
  return (
    <>
      <Header selectedKeys={["product"]} />
      <TopProduct />
      <SideButton label="联系我们" url="/contact" />
      <Footer />
    </>
  );
}
