import Header from './components/Header'
import Sport from './components/Sport'
import Flow from './components/Flow'
import Assess from './components/Assess'
import styles from "./index.module.less";
import React, { useState, useEffect } from "react";
import {valuationUrl} from '../../../common/const'
import { useLocation } from "react-router";

import axios from "axios";

export default function Index() {

  const location = useLocation();

  useEffect(() => {
    // 当路由切换时 滚动到页面顶部
    window.scrollTo(0, 0);
  }, [location]);
  const onLoginClick = () => {

    window.open(valuationUrl, '_blank');
  }
  const [contact, setContact] = useState();

  // function requestCode() {
  //   axios.get(
  //     '/v1/Contact/contactList',
  //     // {params:{type}},
  //   ).then(({ data }) => {
  //     if (data.data && data.code === 200) {
  //       setContact(data.data);
  //     }
  //   });
  // }
  // useEffect(() => {
  //   requestCode(1);
  //   requestCode(2);
  // }, []);
  return (
    <div className={styles.main}>
      <Header onLoginClick={onLoginClick} />
      <div className={styles.sec}>
        {/* <Category onLoginClick={onLoginClick} /> */}
        <Sport />
        <Flow />
      </div>
      <Assess className={styles.bottom}  />

    </div>
  );
}
