import { Image } from "antd";
import classNames from "classnames";
import spot1 from '../../../../../assets/product/spot1.png'
import spot2 from '../../../../../assets/product/spot2.png'
import spot3 from '../../../../../assets/product/spot3.png'
import spot4 from '../../../../../assets/product/spot4.png'
import styles from "./index.module.less";
import { color } from "echarts";

export default function Index() {
  const content = [{
    title: '数据收集与管理',
    subTitle: '系统能够自动收集各类数据，井进行有效管理，用户可以根据需求筛选和导入相关数据；\n系统支持数据预处理和清洗，确保评估质量。',
    content: spot1,
    color:'#067EFC'
  }, {
    title: '价值评估模型',
    subTitle: '系统内置多种价值评估模型，适应不同场景需求；\n用户可以根据实际情况选择合适的评估模型；\n系统支持模型参数调整和优化，提高评估准确性。',
    content: spot2,
    color:'#5053E6'
  }, {
    title: '在线评估流程',
    subTitle: '系统提供在线评估流程，用户可随时进行数据评估；\n评估流程包括数据准备、模型选择、结果输出等步骤；\n系统支持评估过程的监控和调整，确保评估顺利进行。',
    content: spot3,
    color:'#3BB336'
  }, {
    title: '结果展示与导出',
    subTitle: '系统支持评估结果的图形化和文字展示；用户可以方便地查看和理解评估结果；\n系统提供结果导出功能，支持用户进行进一步分析使用。',
    content: spot4,
    color:'#874DFF'
  }];

  return (
    <>
      <div className={styles.title}>产品亮点 </div>

      <div className={styles.mianBack}>
      {content.map(item => (
        <div className={styles.backmy}>
          <div className={styles.header}>
            <div >
              <div className={styles.headerTitle} style={{color :item.color}}>{item.title}</div>
              <div className={styles.headerSubTitle}>{item.subTitle}</div>
            </div>
            <Image className={styles.headerImg} src={item.content} preview={false} />
          </div>
        </div>
      ))}
      </div>
    
    </>
  );
}