import { Button } from "antd";
import styles from "./index.module.less";

export default function Index({ onLoginClick }) {
  return (
    <>
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.headerTitle} />
          <div className={styles.headerInfo}>
          评估模型推荐智能化 | 数据评估指标科学化 <br /> 在线评估操作便捷化 | 估值报告生成自动化
          </div>
         
          <Button className={styles.headerButton} onClick={onLoginClick}>立即评估</Button>
        </div>
      </div>
    </>

  );
}