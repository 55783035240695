import React, { useState } from "react";
import styles from "./index.module.less";
import { Space } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import logo from "../../../assets/newImg/commonImg/xzlLogoWhite.png";
// import bussinessConsult from "../../../assets/newImg/footerImg/bussinessConsult@2x.png";
import osecOfficialAccountQRcode from "../../../assets/newImg/footerImg/osecOfficialAccountQRcode.jpg";
import echaincityOfficialAccountQRcode from "../../../assets/newImg/footerImg/echaincityOfficialAccountQRcode.jpg";

const products = [
  {
    key: "证解",
    href: "/zjapp",
  },
  {
    key: "数据资产价值评估",
    href: "/product",
  },
  // {
  //   key: "信元数藏",
  //   href: "/xysc",
  // },
  {
    key: "可信执法仪",
    href: "/kxzfy",
  },
];

const supports = [
  {
    key: "资讯动态",
    href: "/news",
    param: "news",
  },
  {
    key: "文档中心",
    href: "/document",
    param: "document",
  },
  {
    key: "关于我们",
    href: "/about",
    param: "about",
  },
  {
    key: "联系我们",
    href: "/contact",
    param: "contact",
  },
];

const links = [
  {
    key: "链城数科",
    href: "https://www.echaincity.com",
  },
  {
    key: "趣链科技",
    href: "https://www.hyperchain.cn/",
  },
  {
    key: "浙江大学",
    href: "https://www.zju.edu.cn/",
  },
];

export default function Index(props) {
  const { isOutterLink } = props;

  const relatedContent = !isOutterLink
    ? [
        {
          key: "关于我们",
          href: "/about",
          param: "about",
        },
        {
          key: "联系我们",
          href: "/contact",
          param: "contact",
        },
        {
          key: "文档中心",
          href: "/document",
          param: "document",
        },
      ]
    : [
        {
          key: "关于我们",
          href: "/about",
          param: "about",
        },
      ];

  const [isFooterAboutVisible, setIsFooterAboutVisible] = useState(false);
  const [isFooterProductVisible, setIsFooterProductVisible] = useState(false);
  const [isFooterLinkVisible, setIsFooterLinkVisible] = useState(false);

  const footerAboutContent = (
    <div className={styles.footerLinkBody}>
      {relatedContent.map((item) => {
        return (
          <Link
            className={styles.footerLinks}
            to={{ pathname: item.href, state: { param: item.param } }}
            key={item.param}
          >
            {item.key}
          </Link>
        );
      })}
    </div>
  );
  const footerProductContent = (
    <div className={styles.footerLinkBody}>
      {products.map((item) => {
        if (item.key === "取证中心") {
          return (
            <Link
              to={{ pathname: item.href }}
              target="_blank"
              key={item.key}
              className={styles.footerLinks}
            >
              {item.key}
            </Link>
          );
        } else if (item.key === "信证查询") {
          return (
            <Link
              to={{ pathname: "check" }}
              key={item.key}
              className={styles.footerLinks}
            >
              {item.key}
            </Link>
          );
        } else {
          return (
            <Link
              to={{ pathname: item.href }}
              key={item.key}
              className={styles.footerLinks}
            >
              {item.key}
            </Link>
          );
        }
      })}
    </div>
  );
  const footerLinkContent = (
    <div className={styles.footerLinkBody}>
      {links.map((item) => {
        return (
          <Link
            target="_blank"
            to={{ pathname: item.href }}
            key={item.key}
            className={styles.footerLinks}
          >
            {item.key}
          </Link>
        );
      })}
    </div>
  );

  const phoneFooter = (
    <div className={styles.phoneFooterContainer}>
      <div className={styles.phoneFooter}>
        <div
          className={styles.footerAbout}
          style={isFooterAboutVisible ? { borderBottom: "none" } : null}
        >
          <div
            className={styles.footerBtn}
            onClick={() => setIsFooterAboutVisible(!isFooterAboutVisible)}
          >
            <div className={styles.footerBtnTitle}>关于</div>
            {isFooterAboutVisible ? <MinusOutlined /> : <PlusOutlined />}
          </div>
          {isFooterAboutVisible ? footerAboutContent : null}
        </div>

        <div
          className={styles.footerProduct}
          style={isFooterProductVisible ? { borderBottom: "none" } : null}
        >
          <div
            className={styles.footerBtn}
            onClick={() => setIsFooterProductVisible(!isFooterProductVisible)}
          >
            <div className={styles.footerBtnTitle}>产品</div>
            {isFooterProductVisible ? <MinusOutlined /> : <PlusOutlined />}
          </div>
          {isFooterProductVisible ? footerProductContent : null}
        </div>

        {!isOutterLink && (
          <div
            className={styles.footerLink}
            style={isFooterLinkVisible ? { borderBottom: "none" } : null}
          >
            <div
              className={styles.footerBtn}
              onClick={() => setIsFooterLinkVisible(!isFooterLinkVisible)}
            >
              <div className={styles.footerBtnTitle}>友情链接</div>
              {isFooterLinkVisible ? <MinusOutlined /> : <PlusOutlined />}
            </div>
            {isFooterLinkVisible ? footerLinkContent : null}
          </div>
        )}

        <div className={styles.footerBussinessConusult}>
          {/* <img
            src={bussinessConsult}
            alt="bussinessConsult"
            style={{ width: "232px" }}
          /> */}
        </div>

        <img className={styles.footerLogo} src={logo} alt="信证链logo" />
        <div className={styles.footerSlogan}>
          致力于实现链上链下和异地数据可信协同的全国性运营级公信联盟区块链平台
        </div>

        {!isOutterLink && (
          <div className={styles.footerRecordBox}>
            <div className={styles.footerCopyright}>
              Copyright © 杭州链城数字科技有限公司 版权所有
            </div>
            <div className={styles.footerRecord}>
              {/* <Link
                className={styles.footerRecordLink}
                target="_blank"
                to={{ pathname: "https://beian.miit.gov.cn/" }}
              >
                增值电信业务经验许可证：浙B2-20210667
              </Link> */}
              增值电信业务经验许可证：浙B2-20210667
            </div>
            <div className={styles.footerRecord}>
              <Link
                className={styles.footerRecordLink}
                target="_blank"
                to={{
                  pathname:
                    "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33020902000360",
                }}
              >
                浙公网安备33020902000360号
              </Link>
            </div>
            <div className={styles.footerRecord}>
              浙网信备33010821461688280017号
            </div>
            <div className={styles.footerRecord}>
              <Link
                className={styles.footerRecordLink}
                target="_blank"
                to={{ pathname: "https://beian.miit.gov.cn/#/" }}
              >
                浙ICP 备2021013503号-3
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.section}>
          <img className={styles.logo} src={logo} alt="信证链logo" />
          <div className={styles.slogan}>
            致力于实现链上链下和异地数据可信协同的全国性运营级公信联盟区块链平台
          </div>
          <div className={styles.myHorizontalBar}></div>
          <div className={styles.infomation}>
            <div className={styles.products}>
              <div className={styles.subTitle}>产品</div>
              <Space direction="vertical">
                {products.map((item) => {
                  if (item.key === "取证中心") {
                    return (
                      <Link
                        to={{ pathname: item.href }}
                        target="_blank"
                        key={item.key}
                        className={styles.content}
                      >
                        {item.key}
                      </Link>
                    );
                  } else {
                    return (
                      <Link
                        to={{ pathname: item.href }}
                        key={item.key}
                        className={styles.content}
                      >
                        {item.key}
                      </Link>
                    );
                  }
                })}
              </Space>
            </div>
            <div className={styles.supports}>
              <div className={styles.subTitle}>支持与服务</div>
              <Space direction="vertical">
                {supports.map((item) => {
                  return (
                    <Link
                      className={styles.content}
                      to={{ pathname: item.href, state: { param: item.param } }}
                      key={item.param}
                    >
                      {item.key}
                    </Link>
                  );
                })}
              </Space>
            </div>
            <div className={styles.links}>
              <div className={styles.subTitle}>友情链接</div>
              <Space direction="vertical">
                {links.map((item) => {
                  return (
                    <Link
                      target="_blank"
                      to={{ pathname: item.href }}
                      key={item.key}
                      className={styles.content}
                    >
                      {item.key}
                    </Link>
                  );
                })}
              </Space>
            </div>
            {/* <div className={styles.myVerticalBar}></div> */}
            <div className={styles.followUs}>
              <div className={styles.subTitle}>关注我们</div>
              <div className={styles.qrCodeBox}>
                <img
                  src={osecOfficialAccountQRcode}
                  alt="osec offcial account QRcode"
                  style={{ width: "80px" }}
                />
                <div>信证链助手</div>
              </div>
              <div className={styles.qrCodeBox}>
                <img
                  src={echaincityOfficialAccountQRcode}
                  alt="echaincity offcial account QRcode"
                  style={{ width: "80px" }}
                />
                <div>链城数科</div>
              </div>
            </div>
          </div>

          <div className={styles.footerRecordNumber}>
            Copyright © 杭州链城数字科技有限公司 版权所有
            <span className={styles.spanSpace}>|</span>
            增值电信业务经验许可证：浙B2-20210667
            <span className={styles.spanSpace}>|</span>
            <Link
              target="_blank"
              to={{
                pathname:
                  "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33020902000360",
              }}
            >
              浙公网安备33020902000360号
            </Link>
            <span className={styles.spanSpace}>|</span>
            浙网信备 33010821461688280017 号
            <span className={styles.spanSpace}>|</span>
            <Link
              target="_blank"
              to={{ pathname: "https://beian.miit.gov.cn/#/" }}
            >
              浙ICP 备2021013503号-3
            </Link>
          </div>
        </div>
      </div>
      {phoneFooter}
    </>
  );
}
