// import MainPage from './pages/Main'
import Check from "./pages/Check";
import Cert from "./pages/Cert/Cert";
// import AppDownload from "./pages/Zjapp/Zjapp";
import HomePage from "./pages/HomePage/HomePage";
import About from "./pages/About/About";
import CertInspect from "./pages/CertInspect/CertInspect";
import Document from "./pages/Document/Document";
import NewsPage from "./pages/NewsPage/NewsPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import Zjapp from "./pages/Zjapp/Zjapp";
import NFTCert from './pages/NFTCert/NFTCert';
import FileInspect from './pages/FileInspect';
import AssetCopyright from './pages/AssetCopyright';
import Product from './pages/Product';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import React from "react";
import Kxzfy from "./pages/Kxzfy/Kxzfy";

const componentGroup = [
  {
    path: "/",
    singleComponent: <HomePage />,
  },
  {
    path: "/about",
    singleComponent: <About />,
  },
  {
    path: "/zjapp",
    singleComponent: <Zjapp />,
  },
  {
    path: "/kxzfy",
    singleComponent: <Kxzfy />,
  },
  {
    path: "/cert",
    singleComponent: <CertInspect />,
  },
  {
    path: '/nftcert',
    singleComponent: <NFTCert />
  },
  {
    path: "/certsummary",
    singleComponent: <Cert />,
  },
  {
    path: "/check",
    singleComponent: <Check />,
  },
  {
    path: "/document",
    singleComponent: <Document />,
  },
  {
    path: "/news",
    singleComponent: <NewsPage />,
  },
  {
    path: "/contact",
    singleComponent: <ContactPage />,
  },
  // {
  //   path: "/xysc",
  //   singleComponent: <NFT />,
  // },
  {
    path: "/pdf",
    singleComponent: <Cert />,
  },
  {
    path: "/fileInspect",
    singleComponent: <FileInspect />,
  },
  {
    path: "/assets",
    singleComponent: <AssetCopyright />,
  },
  {
    path: "/product",
    singleComponent: <Product />,
  },
];

export default function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          {componentGroup.map((item) => {
            return (
              <Route path={item.path} exact>
                {item.singleComponent}
              </Route>
            );
          })}
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
}
