import { Image } from "antd";
import classNames from "classnames";
import liucheng1 from '../../../../../assets/product/liucheng1.png'
import liucheng2 from '../../../../../assets/product/liucheng2.png'
import liucheng3 from '../../../../../assets/product/liucheng3.png'
import liucheng4 from '../../../../../assets/product/liucheng4.png'
import liucheng5 from '../../../../../assets/product/liucheng5.png'
import liucheng6 from '../../../../../assets/product/liucheng6.png'
import styles from "./index.module.less";
import React, { useState, useRef } from "react";
import CarouselRef from 'antd/es/carousel';
import { Carousel } from "antd";
import { number } from "echarts";


export default function Index() {

  const handleChangeCurrent = (_, next) => {
    setCurrentIndex(next);
  };

  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const handleMouseEnter = (current) => {
    setAutoPlay(false);
    carouselRef.current?.goTo(current);
  };
  const handleMouseLeave = () => {
    setAutoPlay(true);
  };
  const content = [{
    numIndex: '01',
    title: '用户登录与权限验证',
    subTitle: '用户通过登录界面输入用户名和密码，系统进行验证， 确定用户的角色及其权限，确保只有授权用户才能访问系统和操作数据。',
    content: liucheng1
  }, {
    numIndex: '02',
    title: '评估任务创建',
    subTitle: '用户创建新的评估任务。用户选择需要评估的数据资产，系统生成评估任务并分配任务ID，确保评估过程的组织和管理。',
    content: liucheng2
  }, {
    numIndex: '03',
    title: '评估方法选择',
    subTitle: '提供评估方法的选择和优化工具。用户可以选择人工评估或系统自动评估，系统提供算法优化工具，帮助用户选择最优算法，提高评估的准确性和效率。',
    content: liucheng3,
  }, {
    numIndex: '04',
    title: '模型分析与处理',
    subTitle: '数据资产的分析与处理。系统进行数据分析，计算数据资产的各项指标，进行多维度评估（如市场价值、合规性风险、使用频率等），提供全面的评估结果。',
    content: liucheng4,
  }, {
    numIndex: '05',
    title: '评估结果生成',
    subTitle: '生成评估结果并形成评估报告。系统根据数据评估模型分析的结果生成详细的评估报告，用户可以查看评估结果的详细信息，了解数据资产的价值。',
    content: liucheng5,
  }, {
    numIndex: '06',
    title: '输出结果可视化',
    subTitle: '评估结果的输出与可视化。系统支持定制化报告生成， 根据用户需求生成不同格式的评估报告，并提供数据可视化工具，通过图表、仪表盘等形式展示评估结果，帮助用户更直观地理解数据价值。',
    content: liucheng6,
  }];

  return (
    <>
      <div className={styles.title}>评估流程</div>

      <div className={styles.Top}>
        {content.map((item, index) => (
          <div
            key={item.id}
            className={classNames(styles.TopTitle, currentIndex === index && styles.active)}
            onClick={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave()}
          >
            {item.title}
          </div>
        ))}
      </div>

      <div className={styles.newsRight}>
        <Carousel ref={carouselRef} autoplay={autoPlay} dots={true} beforeChange={handleChangeCurrent}>
          {content.map((item) => (
            <div key={item.id} className={styles.bottom}>
              <div className={styles.Back}>
                <div className={styles.Left}>
                  <div className={styles.letNum}>{item.numIndex}</div>
                  <div className={styles.leftTitle}>{item.title}</div>
                  <div className={styles.leftSubTitle}> {item.subTitle}</div>
                </div>
                <Image className={styles.Right} src={item.content} preview={false} />
              </div>
            </div>
          ))}
        </Carousel>
      </div>

    </>
  );
}