import { Image } from "antd";
import classNames from "classnames";
import bottom from '../../../../../assets/product/bottom.png'

import styles from "./index.module.less";

export default function Index() {

  return (
    <>
      <div className={styles.mianBack}>
        <div className={styles.title}>评估模型</div>
        <div className={styles.image}>
        <Image  src={bottom} preview={false} />
        </div>
      
      </div>
    </>
  );
}